<template>
  <div>
    <el-main>
      <!-- 表单 -->
      <el-button type="primary" style="margin-bottom: 10px" size="small" @click="toDetail()">+添加技工</el-button>
      <el-form class="el-form-search" label-width="140px">
        <el-form-item label="技工姓名：">
          <el-input size="small" placeholder="请输入技工姓名" v-model="searchForm.artisan_name"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：">
          <el-input size="small" placeholder="请输入联系电话" v-model="searchForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="注册时间：">
          <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="归属小区：">
          <el-select v-model="searchForm.community_id" size="small" placeholder="归属小区" clearable>
            <el-option label="全部" :value="0"></el-option>
            <el-option :label="item.community_name" :value="item.id" v-for="item in communityList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" " label-width="25px">
          <el-button type="primary" size="small" @click="search">搜索</el-button>
          <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="type" label="所属小区" align="center" width="120">
          <template v-slot="{ row }">
            <span>
              {{ row.type == 1 ? '平台技工' : row.type == 2 ? '小区技工' : '入驻技工' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="artisan_name" label="技工姓名" align="center" width="80"></el-table-column>
        <el-table-column prop="mobile" label="联系电话" align="center" width="140"></el-table-column>
        <el-table-column prop="service_order_amount" label="累计服务订单金额" align="center" width="140">
          <template v-slot="{ row }">
            <span>￥{{ row.service_order_amount }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="service_order_sum" label="累计服务订单数" align="center" width="140"></el-table-column>
        <el-table-column prop="create_time" label="注册时间" width="200" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
        <el-table-column label="操作" width="160" align="center" fixed="right">
          <template v-slot="{ row }">
            <el-button @click="toRightsDetail(row.id)" type="text" size="small">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 翻页 -->
      <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>
  </div>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        artisan_name: '',
        mobile: '',
        start_time: '',
        end_time: '',
        community_id: '',
      },
      communityList: []
    };
  },
  created() {
    this.getList();
    this.getCommunity();
  },
  methods: {
    search() {
      this.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        type: 0,
        artisan_name: '',
        mobile: '',
        start_time: '',
        end_time: '',
        community_id: '',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.user.artisanList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getCommunity() {
      this.$axios.post(this.$api.user.communityList).then(res => {
        if (res.code == 0) {
          this.communityList = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toDetail() {
      this.$router.push({ path: '/user/addSettled' });
    },
    toRightsDetail(id) {
      this.$router.push({ path: '/user/settedListInfo', query: { id: id } });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
