import { render, staticRenderFns } from "./settedList.vue?vue&type=template&id=6e80e878&scoped=true&"
import script from "./settedList.vue?vue&type=script&lang=js&"
export * from "./settedList.vue?vue&type=script&lang=js&"
import style0 from "./settedList.vue?vue&type=style&index=0&id=6e80e878&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e80e878",
  null
  
)

export default component.exports